var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"institution-pane d-flex flex-column flex-grow-1 flex-shrink-1 flex-wrap"}},[_c('div',{staticClass:"d-flex flex-row  flex-grow-1 flex-wrap flex-shrink-1"},[_c('div',{staticClass:"d-flex flex-column mid-container mid-container-auto"},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle}})],1),(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 flex-wrap"},[_c('v-data-table',{staticClass:"vx-shadow ft mt-2 data-list-list-view",attrs:{"headers":_vm.headers,"items":_vm.archivesList,"loading":_vm.pageLoading,"items-per-page":5},scopedSlots:_vm._u([{key:"item.previewImage",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mt-2",staticStyle:{"width":"40px","height":"40px","border-radius":"50%"},attrs:{"alt":"Product Image","src":item.previewImage
                    ? ("data:image/jpeg;base64," + (item.previewImage))
                    : 'https://via.placeholder.com/150x150'}})]}},{key:"item.amount",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.amount))+" ")])]}},{key:"item.createdAt",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v(_vm._s(_vm._f("timestamp")(item.createdAt,'DD/MM/YYYY')))])]}},{key:"item.paymentStatus",fn:function(ref){
                    var item = ref.item;
return [(item.paymentStatus)?_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.paymentStatus.status === 'success' ? '#9efb9e66' : '#f98c6b',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
                    color: item.paymentStatus.status === 'success' ? 'rgba(3, 91, 3, 0.83)' : '#862c00',
                  })},[_vm._v(_vm._s(item.paymentStatus.status))])]):_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s('No Payment '))])])]}},{key:"item.isPublished",fn:function(ref){
                  var item = ref.item;
return [_c('v-menu',{attrs:{"transition":"slide-y-transition","nudge-bottom":"40","nudge-left":"30","close-on-click":true,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","small":"","color":item.isPublished ? '#9efb9e66' : ''}},on),[_c('span',{staticClass:"font-weight-bold",style:({
                        color: item.isPublished ? 'rgba(3, 91, 3, 0.83)' : 'black',
                      })},[_vm._v(_vm._s(item.isPublished ? 'Published' : 'Not published'))])])]}}],null,true)},[(item.isPublished && !item.isSold)?_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',{staticClass:"ft font-weight-medium pt-2 pb-1",staticStyle:{"height":"auto !important"}},[_vm._v("ACTIONS ")]),_c('v-list-item',{staticStyle:{"min-height":"36px !important"},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('products/publishProduct', {
                        id: item.id,
                        isPublished: false,
                      })}}},[_c('v-list-item-title',{staticClass:"ft font-weight-medium"},[_vm._v("Unpublish Product ")])],1)],1):(!item.isPublished && !item.isSold)?_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',{staticClass:"ft font-weight-medium pt-2 pb-1",staticStyle:{"height":"auto !important"}},[_vm._v("ACTIONS ")]),_c('v-list-item',{staticStyle:{"min-height":"36px !important"},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('products/publishProduct', {
                        id: item.id,
                        isPublished: true,
                      })}}},[_c('v-list-item-title',{staticClass:"ft font-weight-medium"},[_vm._v("Publish Product ")])],1)],1):_vm._e()],1)]}},{key:"item.isSold",fn:function(ref){
                      var item = ref.item;
return [_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","small":"","color":item.isSold ? 'rgba(247, 137, 137, 0.43)' : '#9efb9e66'}},[_c('span',{staticClass:"font-weight-bold",style:({
                    color: item.isSold ? '#c30303' : 'rgba(3, 91, 3, 0.83)',
                  })},[_vm._v(_vm._s(item.isSold ? 'Sold Out' : ("IN Stock (" + (item.quantity) + ")")))])])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"accent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","color":"accent","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('products/moveToArchive', {
                        id: item.id,
                        isArchived: false,
                      })}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("unarchive")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v("Unarchive product")])])]}}],null,false,1909094447)})],1)]):_c('InternetConnection'),_c('SnackBar',{attrs:{"snackbar":_vm.showSnackBar,"text":_vm.message,"timeout":_vm.timeout}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }