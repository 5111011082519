





























































































































































import Component, { mixins } from 'vue-class-component';
import WidgetMixins from '../../mixins/WidgetMixins';
import { currencyFormat, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IProduct } from '@/types/types';
const productsModule = namespace('products');
@Component({
  name: 'Archives',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    SnackBar: loadWidget('widgets/SnackBar'),
    ToolBar: loadWidget('widgets/ToolBar'),
  },
  filters: {
    currencyFormat,
  },
})
export default class Archives extends mixins(WidgetMixins) {
  @productsModule.Getter('getArchivesList') archivesList!: IProduct[];
  headers: Array<{ text: string; value: string }> = [
    {
      text: '',
      value: 'previewImage',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Amount',
      value: 'amount',
    },
    {
      text: 'Published',
      value: 'isPublished',
    },
    {
      text: 'Sold Out',
      value: 'isSold',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];
  created(): void {
    this.$store.dispatch('products/archives');
  }
}
